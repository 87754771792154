import React from "react"
import "../style/style.scss"
import "../../static/video.css"
import "../fonts/icomoon/style.css"

const Layout = ({ children, classname }) => {
  return (
    <div className={classname}>
        {children}
    </div>
  )
}

export default Layout
